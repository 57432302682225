import React from 'react';
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Cargan from './sites/cargan'
import ContextAware from './sites/context-aware'
import ControllableLpcnet from './sites/controllable-lpcnet'
import ControllableProsody from './sites/controllable-prosody'
import PPGs from './sites/ppgs';
import ProminenceEstimation from './sites/prominence-estimation';
import ProMoNet from './sites/promonet';


/*******************************************************************************
Routing logic for project sites
*******************************************************************************/


export default function SiteManager() {
    let match = useRouteMatch();
    return (
      <div>
      <Switch>
        <Route
          path={`${match.path}/cargan`}
          component={Cargan}
        />
        <Route
          path={`${match.path}/context-aware`}
          component={ContextAware}
        />
        <Route
          path={`${match.path}/controllable-lpcnet`}
          component={ControllableLpcnet}
        />
        <Route
          path={`${match.path}/controllable-prosody`}
          component={ControllableProsody}
        />
        <Route
          path={`${match.path}/ppgs`}
          component={PPGs}
        />
        <Route
          path={`${match.path}/prominence-estimation`}
          component={ProminenceEstimation}
        />
        <Route
          path={`${match.path}/promonet`}
          component={ProMoNet}
        />
      </Switch>
      </div>
    );
}
