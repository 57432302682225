import React from 'react';

import Project from './project';
import projectList from './json/projects.json'


/*******************************************************************************
Project list
*******************************************************************************/


export default function ProjectList() {
  const projects = projectList.entries.map(
    (entry, index) => <Project key={index} metadata={entry}/>);
  return <div className='row px-3'>{projects}</div>;
}
