import React from 'react';

import AudioPlayer from './audioplayer.js';

import './css/song.css'
import './css/responsive-text.css'


/*******************************************************************************
Song
*******************************************************************************/


export default function Song({metadata, onPlay, onPause}) {
  const attributions = get(metadata, 'attributions');
  const filename = get(metadata, 'filename');
  const src = process.env.PUBLIC_URL + '/audio/' + filename;
  return (
    <div className='row song-row'>
      <div className='song-title'>
        <p className='inline-text text-22-responsive'>
          {get(metadata, 'title')}
        </p>
        &nbsp;
        <div className='inline-text text-16-responsive'>
          {attributions}
        </div>
      </div>
      <AudioPlayer onPlay={onPlay} onPause={onPause} src={src}/>
    </div>);
}


/*******************************************************************************
Subcomponents
*******************************************************************************/


// function Link({attributions}) {
//   /* Replace attribution text names with hyperlinks */
//   if (typeof attributions === 'undefined') {
//     return null;
//   }
//   attributions = attributions[0];
//   const idx = attributions.search('Anna Brooks');
//   if (idx === -1) {
//     return null;
//   }
//   return (
//     <div className='inline-text'>
//       {attributions.slice(0, idx)}
//       <a href='https://www.annabrooksepk.com/'>Anna Brooks</a>
//     </div>);
// }


/*******************************************************************************
Utilities
*******************************************************************************/


function get(metadata, property) {
  try {
    return metadata[property];
  } catch(err) {
    return '';
  }
}
