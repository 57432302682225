import React from 'react';

import './css/audioplayer.css';


/*******************************************************************************
Audio player
*******************************************************************************/


export default class AudioPlayer extends React.Component {

  constructor(props) {
    super(props);

    this.state =  {
      currentTime: 0,
      dragging: false,
      dotPosition: 0,
      playing: false,
      totalTime: 0,
      wasPlaying: false
    }

    // Dot
    this.dotRef = React.createRef();

    // Audio element
    this.playerRef = React.createRef();

    // Audio player track
    this.progressRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    /* Callback for state changes */
    // Handle change to playing state
    if (this.state.playing !== prevState.playing) {
      if (this.state.playing) {

        // Play audio
        this.playerRef.current.play();
        this.props.onPlay(this);
      } else {

        // Stop audio
        this.playerRef.current.pause();
        this.props.onPause(this);
      }
    }
  }

  render() {
    return (
      <div className='row player'>
        {this.playPauseIcon()}
        {this.currentTime()}
        {this.timeline()}
        {this.totalTime()}
        {this.audio()}
      </div>);
  }

  /*****************************************************************************
  Subcomponents
  *****************************************************************************/

  audio = () => {
    /* Render audio element */
    // Audio callbacks
    const onTimeUpdate = () => {
      this.setState({currentTime: this.playerRef.current.currentTime});
    }
    const onLoadedMetadata = () => {
      this.setState({totalTime: this.playerRef.current.duration});
    }

    // Render
    return <audio
      ref={this.playerRef}
      src={this.props.src}
      onTimeUpdate={onTimeUpdate}
      onLoadedMetadata={(onLoadedMetadata)}
      onEnded={this.reset}
    />
  }

  currentTime = () => {
    /* Render current time */
    // Optionally hide timeline
    if (this.props.hideTimeline) { return null; }

    // Render
    return (
      <div className='col-2 p-0 mx-2'>
        {formatTime(this.state.currentTime)}
      </div>);
  }

  playPauseIcon = () => {
    /* Render play or pause icon */
    // Select icon
    const icon = this.state.playing || this.state.wasPlaying ? 'pause' : 'play';

    // Render
    return (
      <div className='col-1' onClick={this.togglePlay}>
        <i className={'pointer fa fa-' + icon}></i>
      </div>);
  }

  timeline = () => {
    /* Render the audio timeline */
    // Optionally hide timeline
    if (this.props.hideTimeline) { return null; }

    // Render
    return (
      <div ref={this.progressRef} className='col-4 p-0'>

        {/* Audio player track */}
        <div className='progress-bar' onClick={this.seek}/>

        {/* Dot for seeking */}
        <div
          className='dot'
          ref={this.dotRef}
          style={{left: this.timeToPosition(this.state.currentTime)}}
          onMouseDown={this.onMouseDown}
        />
      </div>);
  }

  totalTime = () => {
    /* Render total time */
    // Optionally hide timeline
    if (this.props.hideTimeline) { return null; }

    // Render
    return (
      <div className='col-2 p-0 ml-2'>
        {formatTime(this.state.totalTime)}
      </div>);
  }

  /*****************************************************************************
  Audio controls
  *****************************************************************************/

  reset = () => {
    /* Reset the audio element */
    if (this.state.dragging) { return; }
    this.togglePlay();
    this.setState({currentTime: 0});
  }

  togglePlay = () => {
    /* Toggles whether this audio player is playing */
    this.setState((state) => ({playing: !state.playing}));
  }

  /*****************************************************************************
  Seek callbacks
  *****************************************************************************/

  onMouseDown = (e) => {
    /* Callback for beginning to seek when dot is clicked */
    // Only respond to left mouse button
    if (e.button !== 0) { return; }

    // Stop audio and note if it was currently playing
    const audio = this.playerRef.current;
    if (audio.duration > 0 && !audio.paused) {
      this.setState({wasPlaying: true, playing: false});
    }

    // Store the current position of the dot before dragging
    const body = document.body;
    const box = this.dotRef.current.getBoundingClientRect();
    this.setState({
      dotPosition: e.pageX - (box.left + body.scrollLeft - body.clientLeft),
      dragging: true
    });

    // Start listening for dragging or mouse up
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);

    e.preventDefault();
  }

  onMouseMove = (e) => {
    /* Callback for dragging the dot */
    // Get leftmost position of the dot
    const pinLeft = e.pageX - this.state.dotPosition;

    // Convert to time in seconds
    const newTime = this.positionToTime(pinLeft);

    // Update audio player
    this.playerRef.current.currentTime = newTime;

    // Update displayed time
    this.setState({currentTime: newTime});

    e.preventDefault();
  }

  onMouseUp = (e) => {
    /* Callback for releasing the dot */
    // Stop listening
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);

    // Continue playing if we were playing during onMouseDown
    this.setState(state => ({
      dragging: false,
      playing: state.wasPlaying,
      dotPosition: 0,
      wasPlaying: false
    }));

    e.preventDefault();
  }

  seek = (e) => {
    /* Jump to location when location on player is clicked */
    // Get player width
    const parentBox = this.progressRef.current.getBoundingClientRect();
    const parentWidth = parentBox.right - parentBox.left;

    // Get location that was clicked
    const location = (e.pageX -  parentBox.left);

    // Conver to time in seconds
    const newTime = (location / parentWidth) * this.state.totalTime;

    // Update audio player
    this.playerRef.current.currentTime = newTime;

    // Update displayed time
    this.setState({currentTime: newTime});
  }

  /*****************************************************************************
  Time and position conversion
  *****************************************************************************/

  positionToTime = (position) => {
    /* Convert from dot position (set by user) to time in seconds */
    // Get the dot width
    const pinBox = this.dotRef.current.getBoundingClientRect();
    const pinWidth = pinBox.right - pinBox.left;

    // Get the audio player width
    const progressBox = this.progressRef.current.getBoundingClientRect();
    const progressWidth = progressBox.right - progressBox.left - pinWidth;

    // Get dot position
    const leftBound = progressBox.left;
    const rightBound = progressBox.right - pinWidth;
    position = Math.min(rightBound, Math.max(leftBound, position));

    // Compute fractional progress
    const progress = (position - progressBox.left) / progressWidth;

    // Convert to time in seconds
    return progress * this.state.totalTime;
  }

  timeToPosition = (time) => {
    /* Convert time in seconds to dot position in pixels */
    // Default to position 0 if not yet loaded
    if (!this.progressRef.current || this.state.totalTime === 0) { return 0; }

    // Bound current time between 0 and audio length
    time = Math.min(this.state.totalTime, Math.max(0, time));

    // Get width of audio player
    const progressBox = this.progressRef.current.getBoundingClientRect();
    const progressWidth  = progressBox.right - progressBox.left;

    // Get width of dot
    const pinBox = this.dotRef.current.getBoundingClientRect();
    const pinWidth = pinBox.right - pinBox.left;

    // Compute current fractional progress
    const progress = time / this.state.totalTime;

    // Convert to dot position in pixels
    return progress * (progressWidth - pinWidth);
  }
}


/*******************************************************************************
Utilities
*******************************************************************************/


function formatTime(time) {
  /* Convert from time in seconds to format minutes:seconds */
  let minuntes = Math.floor(time / 60);
  let seconds = Math.floor(time % 60);
  return minuntes + ':' + ((seconds < 10) ? ('0' + seconds) : seconds);
}
