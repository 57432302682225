import React from 'react'
import ReactMarkdown from 'react-markdown'
import Site from '../site'

import { Audio, Download } from '../sitecomponents'
import WavTable from '../wavtable'

import '../css/site.css'


export default function ContextAware() {
  const name = 'context-aware';
  return (
    <Site name={name}>
      <Example />
      <Replacement name={name}/>
    </Site>);
}


const Example = () => {
  const example_0 = `### Speech replacement example\n` +
  `Consider editing a speech file (e.g., for a podcast or film), such as ` +
  `the following.`;

  const example_1 = `Say we want to replace the words _"the Folkstone ` +
  `office for some time"_. We find a suitable replacement elsewhere in the ` +
  `recording, but directly copying and pasting in the waveform domain ` +
  `sounds strange due to discontinuities or repetitions in the pitch ` +
  `and speaking rate (i.e., _prosodic_ errors).`;

  const example_2 = `The goal of this work is to permit this style of ` +
  `speech editing by using neural networks to synthesize a correct ` +
  `prosody, then pitch-shifting and time-stretching the ` +
  `speech that is pasted in so it exhibits the generated prosody. ` +
  `For example, the result may sound like the following.`;

  const example_3 = `This example was selected for illustrative purposes. In the ` +
  `next section, we include a broader range of audio examples from our experiment.`;

  return (
    <div className='section'>
      <ReactMarkdown source={example_0} />
      <Audio file={'original.mp3'} />
      <ReactMarkdown source={example_1} />
      <Audio file={'bad.mp3'} />
      <ReactMarkdown source={example_2} />
      <Audio file={'good.mp3'} />
      <ReactMarkdown source={example_3} />
    </div>
  );
}


const Replacement = ({ name }) => {
  const replacement = `### Audio examples\n` +
  `The following are audio examples from the perceptual task defined in ` +
  `section 3 of our paper. Rather than manually defining phrases to ` +
  `copy and paste, we find repeated phrases by the same speaker, but with ` +
  `different prosody. We generate prosody to make one repetition of the ` +
  `phrase sound natural when used in place of the other repetition. ` +
  `We briefly describe the experiment conditions below.\n` +
  `  - **Original** - The original speech recording\n` +
  `  - **Naive** - The result of copy-pasting the waveforms directly\n` +
  `  - **Average** - Uses monotone pitch and average phoneme durations\n` +
  `  - **Proposed** - Our proposed method\n` +
  `  - **-Duration** - Our method, but with the same phoneme durations as Naive\n` +
  `  - **-Pitch** - Our method, but with the same pitch as Naive\n` +
  `  - **-HiFi-GAN** - Our method without HiFi-GAN\n` +
  `  - **-Context** - Our method without context awareness\n` +
  `  - **Tacotron** - Uses pitch and phoneme durations produced by a ` +
  `multispeaker Tacotron model\n\n` +
  `The following phrases are replaced in the below examples.\n` +
  `  - **Female 0** - "to be found"\n` +
  `  - **Female 1** - "she had"\n` +
  `  - **Male 0** - "seen him"\n` +
  `  - **Male 1** - "was about"`;

  const columns = ['Female 0', 'Female 1', 'Male 0', 'Male 1']
  return (
    <div className='section'>
      <ReactMarkdown source={replacement} />
      <WavTable
        name={name}
        experiment={'replacement'}
        extension='mp3'
        columns={columns}
        rows={['Original', 'Naive', 'Average', 'Proposed', '-Duration', '-Pitch', '-HiFi-GAN', '-Context', 'Tacotron']}
        transpose={true}
      />
      <Download file={'user-study.tar.gz'} text={'Download all evaluation data'} />
    </div>
  );
}
