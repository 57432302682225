import React, { useState } from 'react';

import Song from './song';

import './css/playlist.css';

import playlist from './json/music.json';


/*******************************************************************************
Playlist
*******************************************************************************/


export default function Playlist() {
  /* Renders a list of audio elements */
  const [active, setActive] = useState(null);

  // Callbacks for setting and clearing active audio element
  const onPlay = (player) => {
    if (active !== null) { active.togglePlay(); }
    setActive(player);
  }
  const onPause = (player) => {
    if (active === player) { setActive(null); }
  }

  // List of audio elements
  const songs = playlist.entries.map((entry, index) =>
    <Song onPlay={onPlay} onPause={onPause} key={index} metadata={entry}/>
  );

  // Render
  return <div className='playlist'>{songs}</div>;
}
