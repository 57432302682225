import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import './css/wavtable.css'


/*******************************************************************************
WavTable
*******************************************************************************/


export default function WavTable({experiment,
                                  extension,
                                  rows,
                                  columns,
                                  transpose}) {
  /* Renders a html table of cells containing audio controls */
  return (
    <table className='wavtable'>
      <TableHeader rows={rows} columns={columns} />
      <TableBody
        experiment={experiment}
        extension={extension}
        rows={rows}
        columns={columns}
        transpose={transpose}
      />
    </table>);
}


/*******************************************************************************
Subcomponents
*******************************************************************************/


function Audio({ experiment, extension, row, col, transpose }) {
  /* Renders an audio object in the table */
  const match = useRouteMatch();

  let path = ``;
  if (transpose)
    path = `${match.path}/audio/${experiment}/${col}/${row}.${extension}`;
  else
    path = `${match.path}/audio/${experiment}/${row}/${col}.${extension}`;

  return (
    <audio
      controls
      preload='metadata'
      className='audio-table'
      src={path}
    />);
}


function TableBody({ experiment, extension, rows, columns, transpose }) {
  /* Constructs the JSX of the table body */
  let key = 0, tableBody = [];
  for (const row of rows) {

    // Start new row
    let elements = [];
    if (row !== '_') {
      elements.push(<th className='small-column' scope='row' key={key}>{row}</th>);
      key += 1;
    }

    for (const col of columns) {

      // Add cell to the row
      elements.push(
        <td className='wav-entry' key={key}>
          <Audio
            experiment={experiment}
            extension={extension}
            row={row}
            col={col}
            transpose={transpose}
          />
        </td>);
      key += 1;
    }

    // Add new row to table
    tableBody.push(<tr key={key}>{elements}</tr>);
    key += 1
  }

  return (<tbody>{tableBody}</tbody>);
}


function TableHeader({ rows, columns }) {
  /* Constructs the JSX of the table header */
  // Assign each element a unique id
  let key = 0;

  // Leave the top left cell blank
  let tableHeader = [];
  if (rows.some((row) => row !== '_')) {
    tableHeader.push([
      <th className='small-column' scope='col' key={key}></th>]);
    key += 1;
  }

  // Get the header text
  for (const col of columns) {

    // Add column name to header
    tableHeader.push(
      <th className='column-text' scope='col' key={key}>{col}</th>);
    key += 1;
  }

  return (<thead><tr>{tableHeader}</tr></thead>);
}
