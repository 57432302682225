import React from 'react'
import ReactMarkdown from 'react-markdown'
import Site from '../site'

import { Image } from '../sitecomponents'
import WavTable from '../wavtable'

import '../css/site.css'


export default function ControllableProsody() {
  const name = 'controllable-prosody';
  const rows = ['94 (0)', '94 (1)', '3906 (0)', '3906 (1)',
                '5717 (0)', '5717 (1)', '11049 (0)', '11049 (1)'];
  return (
    <Site name={name}>
      <ControlF0 name={name} />
      <Lowpass rows={rows} />
      <Repunctuate rows={rows} />
      <PitchShifting rows={rows} />
    </Site>);
}


const ControlF0 = ({ name }) => {
  const intro = '### Controllable F0 Generation\n' +
    'Here we demonstrate a core idea of our work: flexibly controlling ' +
    'the F0 contour of speech. Specifically, we will change ' +
    'an emphasis in a speech utterance using a GUI we developed on top of ' +
    'our C-DAR model for F0 generation. Here is the speech utterance we ' +
    'will use for demonstration:';
  const edit = 'Let\'s emphasize the word "trembling". We can do this by ' +
    'clicking-and-dragging the mouse to draw our desired pitch over the word ' +
    '"trembling"—as seen below. Any regions with black lines (i.e., user ' +
    'edits) will be fed as conditioning the C-DAR model. The conditioning ' +
    'method is the same as described in Section 2.2 of the paper.';
  const generate = 'Now, we\'ll click "generate" and have our C-DAR model ' +
    'generate a new F0 contour using the given user constraint:';
  const outro = 'The generated speech respects the user-provided contour ' +
    'and maintains a natural prosody. While the above example uses a ' +
    'DSP-based pitch-shifting vocoder for synthesis, we show in our work ' +
    'that neural vocoders like WaveNet can be adapted to perform ' +
    'pitch-shifting via a simple modification.';
  const originalCaption = 'Our GUI displaying a speech utterance and its ' +
    'original F0 contour. Dashed vertical lines indicate where each word ' +
    'starts. The frequency scale spans four standard deviations ' +
    'above and below the speaker\'s average pitch in base-2 log-space.';
  const editCaption = 'The user has drawn a black line over the word ' +
    '"trembling" by clicking-and-dragging their mouse.';
  const shiftedCaption = 'After clicking "generate", our GUI displays the ' +
    'speech utterance with a F0 contour generated by our model.';
  return (
    <div className='section'>
      <ReactMarkdown source={intro} />
      <Image
        alt='GUI with text and original F0'
        audio='bridge-original.mp3'
        caption={originalCaption}
        file='bridge-original.png'
        name={name}
        width={700}
      />
      <ReactMarkdown source={edit} />
      <Image
        alt='GUI with text, original F0, and a drawn-in edit to the F0'
        caption={editCaption}
        file='bridge-edit.png'
        name={name}
        width={700}
      />
      <ReactMarkdown source={generate} />
      <Image
        alt='GUI with text and shifted F0'
        audio='bridge-shifted.mp3'
        caption={shiftedCaption}
        file='bridge-shifted.png'
        name={name}
        width={700}
      />
      <ReactMarkdown source={outro} />
    </div>);
}


const Lowpass = ({ rows }) => {
  const lowpass = '### Evaluating F0 naturalness\n' +
    'Prior methods for evaluating F0 generation models for speech have a ' +
    'problem: the naturalness of the F0 contour is not evaluated ' +
    'independently from the vocoder. ' +
    'As we heard above, pitch-shifting vocoders ' +
    'induce artifacts relative to the size of the shift. This ' +
    'penalizes natural-sounding F0 contours with larger distance to the ' +
    'ground truth contour, and rewards unnatural contours with smaller ' +
    'distance to the ground truth. We propose an additional evaluation ' +
    'method that solves this issue by low-pass filtering. This ' +
    'removes all vocoder artifacts above the cutoff frequency, but also masks ' +
    'the relationship between text and prosody—participants now evaluate ' +
    'whether the F0 contour sounds natural, rather than whether the F0 ' +
    'contour sounds natural _given_ the text. In short, our method has ' +
    'orthogonal strengths and weaknesses to existing evaluation methods, ' +
    'making it a useful evaluation when performed in conjunction with ' +
    'vocoding-based evaluation.';
  const examples = 'Here we provide low-passed examples for ' +
    'eight utterances. We include the original ' +
    'F0 contour, as well as F0 contours generated from the baseline DAR, the ' +
    'proposed C-DAR, and one of our random prosody models (the _replace_ ' +
    'model described in Section 4.2 of the paper). The original speech ' +
    'samples used to create these low-passed examples can be found in ' +
    'section "Pitch-Shifting WaveNet" below. We recommend listening to those ' +
    'samples last, as knowing the underlying words beforehand will bias ' +
    'your listening experience.';
  return (
    <div className='section'>
      <ReactMarkdown source={lowpass}/><br />
      <ReactMarkdown source={examples}/>
      <WavTable
        experiment='lowpass'
        extension='mp3'
        rows={rows}
        columns={[
          'Original',
          'DAR (baseline)',
          'C-DAR (proposed)',
          'Random (replace)'
        ]}
      />
    </div>);
}


const PitchShifting = ({ rows }) => {
  const intro = '### Pitch-Shifting WaveNet Vocoder (PS-WaveNet)\n' +
    'We augment WaveNet by squeezing the input acoustic features through ' +
    'a bottleneck and providing an explicit F0 contour as conditioning ' +
    'after the bottleneck. During inference, we can use a different F0 ' +
    'contour than the original to perform pitch shifting. We call this model ' +
    'a Pitch-Shifting WaveNet, or PS-WaveNet. Here we provide audio examples ' +
    'from PS-WaveNet as well as DSP-based vocoders PSOLA and WORLD. All ' +
    'examples are generated at 22050 Hz.';
  const original = '#### Vocoding with original F0\n' +
    'The following examples are generated using the F0 contour of the ' +
    'original speech.';
  const shifted = '#### Vocoding with generated F0\n' +
    'The following examples are generated using F0 contours generated by ' +
    'C-DAR.';
  return (
    <div className='section'>
      <ReactMarkdown className='subsection' source={intro} />
      <ReactMarkdown source={original} />
      <div className='subsection'>
        <WavTable
          experiment='original'
          extension='mp3'
          rows={rows}
          columns={['Original', 'World', 'Psola', 'PS-WaveNet (proposed)']}
        />
      </div>
      <ReactMarkdown source={shifted}/>
      <WavTable
        experiment='shifted'
        extension='mp3'
        rows={rows}
        columns={['World', 'Psola', 'PS-WaveNet (proposed)']}
      />
    </div>);
}


const Repunctuate = ({ rows }) => {
  const repunctuate = '### Repunctuation\n' +
    'To test the controllability of C-DAR, we propose the task of changing ' +
    'the sentence punctuation from a question mark to a period, or vice ' +
    'versa. We call this task "repunctuation". We compare C-DAR to the ' +
    'baseline DAR model as well as a ' +
    'heuristic baseline that replaces the F0 of the last two words with a ' +
    'manually-selected F0 contour representative of the target punctuation. ' +
    'As before, we low-pass filter the ' +
    'speech to remove vocoder artifacts. Importantly, this also hides ' +
    'whether the original sentence was a question or statement. Here we ' +
    'provide samples from our experiment using the same sentences as in ' +
    'the previous section. For each sample, the target punctuation is the ' +
    'opposite of the punctuation in the original sentence (i.e., all ' +
    'questions are converted to statements and vice versa). The original ' +
    'speech utterances can be heard in the following section.';
  return (
    <div className='section'>
      <ReactMarkdown source={repunctuate} />
      <WavTable
        experiment='repunctuate'
        extension='mp3'
        rows={rows}
        columns={['Heuristic (baseline)', 'DAR (baseline)', 'C-DAR (proposed)']}
      />
    </div>
  );
}
