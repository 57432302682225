import BibtexParse from 'bibtex-parse';
import React, {useState, useEffect} from 'react';

import BibTex from './bibtex'

import './css/site.css';
import './css/responsive-text.css';


/*******************************************************************************
Project site
*******************************************************************************/


export default function Site(props) {
  /* Base class for project websites

  props:
    name (string) - The project name
  */
  const url = process.env.PUBLIC_URL + '/sites/' + props.name + '/';

  // Load bibtex
  const [bibtex, setBibtex] = useState(null);
  useEffect(() => {
    fetch(url + props.name + '.bibtex')
      .then((response) => response.text())
      .then((text) => setBibtex(BibtexParse.entries(text)[0]));
  }, [url, props.name])

  // Render nothing until bibtex is loaded
  if (bibtex === null) { return null; }

  // Render project site
  return (
    <div className='project'>
      <Title bibtex={bibtex}/>
      <Authors bibtex={bibtex}/>
      <Links bibtex={bibtex}/>
      <Abstract bibtex={bibtex}/>
      {props.children}
      <Citation bibtex={bibtex}/>
    </div>);
}


/*******************************************************************************
Subcomponents
*******************************************************************************/


function Abstract({bibtex}) {
  /* Renders the abstract */
  return (
    <div className='abstract'>
      <h3>Abstract</h3>
      <p>{get(bibtex, 'abstract')}</p>
    </div>);
}


function Authors({bibtex}) {
  /* Renders the authors */
  const authors = getAuthors(bibtex).map(
    (author, key) => <span className='author' key={key}>{author}</span>);
  return <div align='center'>{authors}</div>;
}


function Button({name, item, icon, metadata}) {
  let value = get(metadata, item);
  if (value) {
    value = item === 'key' ? '/pdfs/' + value + '.pdf' : value;
    return (
      <a className='iconbox' href={value}>
        <i className={icon + ' text-24-responsive pb-1'}></i>
        <div className='line-height-100 text-22-responsive'>{name}</div>
      </a>);
  } else { return null; }
}


function Citation({bibtex}) {
  /* Renders citation in both BibTex and IEEE */
  return (
    <div className='citations'>
      <h3>Citation</h3>
      <h5>IEEE</h5>
      <IEEE bibtex={bibtex}/>
      <h5>BibTex</h5>
      <div style={{fontSize: 14}}>
        <BibTex bibtex={bibtex}/>
      </div>
    </div>);
}


function IEEE({bibtex}) {
  /* Renders citation in IEEE format */
  let booktitle = get(bibtex, 'booktitle');
  let journal = get(bibtex, 'journal');
  return (
    <div className='subsection'>
      <p style={{fontSize: 14}}>
        {' ' + get(bibtex, 'author') + ', "' + get(bibtex, 'title') + '," '}
        {booktitle ? <i>{booktitle}</i> : null}
        {journal ? <i>{journal}</i> : null}
        {'. ' + get(bibtex, 'month') + ' ' + get(bibtex, 'year') + '.'}
      </p>
    </div>);
}


function Links({bibtex}) {
  /* Renders the bar of related links */
  return (
    <div className='links'>
      <Button
        icon={'far fa-file'}
        item={'key'}
        metadata={bibtex}
        name={'Paper'}
      />
      <Button
        icon={'fas fa-code-branch'}
        item={'github'}
        metadata={bibtex}
        name={'Code'}
      />
      <Button
        icon={'fas fa-columns'}
        item={'poster'}
        metadata={bibtex}
        name={'Poster'}
      />
      <Button
        icon={'far fa-file-powerpoint'}
        item={'slides'}
        metadata={bibtex}
        name={'Slides'}
      />
      <Button
        icon={'fas fa-film'}
        item={'youtube'}
        metadata={bibtex}
        name={'Video'}
      />
    </div>
  );
}


function Title({bibtex}) {
  /* Renders the project title */
  return <h2 className='title' align='center'>{get(bibtex, 'title')}</h2>;
}


/*******************************************************************************
Utilities
*******************************************************************************/


function get(bibtex, property) {
  /* Retrieve a BibTex entry property */
  try {
    if (['key', 'type'].includes(property)) {
        return bibtex[property];
    } else {
        return bibtex[property.toUpperCase()];
    }
  } catch(error) { return null; }
}

function getAuthors(metadata) {
  /* Retrieve the author list */
  // Get raw string from bibtex
  let authors = get(metadata, 'author').replace('\\\'e', 'é').split(' and ');

  // Parse individual names
  let result = [];
  for (const author of authors) {
    result.push(author.split(', ').reverse().join(' '));
  }

  return result;
}
