import React, { useState, useEffect } from 'react';
import { Switch, Route, Link, useLocation } from "react-router-dom";

import Bibliography from './bibliography';
import Home from './home';
import NotFound from './notfound';
import Playlist from './playlist';
import ProjectList from './projectlist';
import SiteManager from './sitemanager';
import scholar from './images/gs.png'

import { useWindowSize, useWindowSizeDelta } from './hooks';

import './css/app.css';
import './css/responsive-text.css'


/*******************************************************************************
Personal website entry point
*******************************************************************************/


export default function App() {
  // Hide the sidebar by default on project sites
  const [projectSite, setProjectSite] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setProjectSite(location.pathname.split('/')[1] === 'sites');
  }, [location, projectSite]);

  // Sidebar display logic
  const [toggle, setToggle] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const windowSize = useWindowSize();
  useEffect(() => {
    setShowSidebar(windowSize.width > 768 && (!projectSite || toggle));
  }, [projectSite, toggle, windowSize]);

  // Full-view sidebar logic
  const [sidebarFull, setSidebarFull] = useState(false);
  useEffect(() => {
    setSidebarFull((windowSize.width <= 768 && toggle));
  }, [toggle, windowSize]);

  // Topbar display logic
  const [showTopbar, setShowTopbar] = useState(false);
  useEffect(() => {
    setShowTopbar(!sidebarFull && (windowSize.width <= 768 || (projectSite && !toggle)));
  }, [projectSite, sidebarFull, toggle, windowSize]);

  // Reset toggle when display changes or url changes
  const windowSizeDelta = useWindowSizeDelta();
  const [prevLocation, setPrevLocation] = useState(location);
  useEffect(() => {
    const width = windowSize.width;
    const delta = windowSizeDelta.width;
    const newLocation = location !== prevLocation;

    // Reset toggle
    if (newLocation || ((width <= 768 && width + delta > 768) ||
                        (width > 768 && width + delta <= 768))) {
      setToggle(false);
    }

    // Update url
    if (newLocation) {
      setPrevLocation(location);
    }
  }, [location, prevLocation, sidebarFull, windowSize, windowSizeDelta]);

  // Pad the content when the sidebar is present
  const pad = projectSite || windowSize.width <= 768 ? 'p-0' : 'pad-left-250';

  return (
    <div className={'container ' + pad}>
      <Topbar
        showTopbar={showTopbar}
        setToggle={setToggle}
      />
      <Sidebar
        projectSite={projectSite}
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
        sidebarFull={sidebarFull}
        setToggle={setToggle}
        toggle={toggle}
      />
      <Content
        showSidebar={showSidebar}
        sidebarFull={sidebarFull}
      />
    </div>);
}


function Content({showSidebar, sidebarFull}) {
  if (sidebarFull) { return null; }
  const classes = showSidebar ? 'width-minus-sidebar' : 'w-100 margin-top-50';
  return (
    <div className={'content ' + classes}>
      <Switch>
        <Route exact path='/' component={Home}/>
        <Route path='/projects' component={ProjectList}/>
        <Route path='/publications' component={Bibliography}/>
        <Route path='/music' component={Playlist}/>
        <Route path='/sites' component={SiteManager}/>
        <Route component={NotFound}/>
      </Switch>
    </div>);
}


function Sidebar({projectSite,
                  showSidebar,
                  setShowSidebar,
                  sidebarFull,
                  toggle,
                  setToggle}) {
  let classes = 'hide';
  if (sidebarFull) {
    classes = 'allbar';
  } else if (showSidebar) {
    classes = 'collapsable';
  }

  const hideSidebarProjectSite = () => {
    if (projectSite && toggle && !sidebarFull) {
      setShowSidebar(false);
      setToggle(false);
    }
  }

  return (
    <div className={classes} id='sidebar' onMouseLeave={hideSidebarProjectSite}>

      {/* Header */}
      <div className='sidebar-header'>
        <h1 className='text-25-5 text-tight m-0'>MAX MORRISON</h1>
        <h2 className='text-12 text-tight m-0'>
          Machine Learning + Audio Researcher
        </h2>
      </div>

      {/* Sidebar rows */}
      <ul className='list-unstyled components text-20'>
        <SidebarRow
          link={'/'}
          text={'Home'}
          icon={'fa fa-home'}
          setToggle={setToggle}
        />
        <SidebarRow
          link={'/publications'}
          text={'Publications'}
          icon={'fa fa-pen'}
          setToggle={setToggle}
        />
        <SidebarRow
          link={'/projects'}
          text={'Projects'}
          icon={'fa fa-code'}
          setToggle={setToggle}
        />
        <SidebarRow
          link={'/music'}
          text={'Music'}
          icon={'fa fa-music'}
          setToggle={setToggle}
        />
        <li>
          <a className='sidebar-row' href={'/pdfs/cv.pdf'}>
            <i className='fix-width-icon fas fa-file'></i> CV
          </a>
        </li>

        {/* Footer */}
        <div className='footer text-center'>
          <a className='social-button' href='mailto:maxrmorrison@gmail.com'>
            <i className='fa fa-envelope text-28 mb-1'></i>
          </a> &nbsp;
          <a className='social-button' href='https://scholar.google.com/citations?user=DfjXyrEAAAAJ&hl=en'>
            <img src={scholar} alt='Google scholar' className='text-28 mb-1' style={{width: '.8em'}}/>
          </a> &nbsp;
          <a className='social-button' href='https://github.com/maxrmorrison'>
            <i className='fab fa-github text-28 mb-1'></i>
          </a> &nbsp;
          <a className='social-button' href='https://twitter.com/maxrmorrison'>
            <i className='fab fa-twitter text-28 mb-1'></i>
          </a>
          <div className='text-14 mb-3'>© Copyright 2024 Max Morrison</div>
        </div>
      </ul>
    </div>);
}


function SidebarRow({link, text, icon, setToggle}) {
  return (
    <li>
      <Link
        to={link}
        className='sidebar-row'
        onClick={() => setToggle(false)}
      >
        <i className={'fix-width-icon ' + icon}></i> {text}
      </Link>
    </li>);
}


function Topbar({showTopbar, setToggle}) {
  return (
    <div className={'topbar' + (showTopbar ? ' d-flex' : '')}>
      <div
        className='pub-icon'
        onClick={() => setToggle(true)}
      >
        <i className="fas fa-bars"></i>
      </div>
    </div>);
}
