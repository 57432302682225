import React from 'react';

import './css/project.css';


/*******************************************************************************
Project component
*******************************************************************************/


export default function Project({metadata}) {
  const title = get(metadata, 'title');
  return (
    <div className='project-card'>
      <img
        className='w-100'
        src={process.env.PUBLIC_URL + '/images/' + get(metadata, 'image')}
        style={{height: '200'}}
        alt={title}
      />
      <div className='card-title text-24 mb-0 pt-1'>{title}</div>
      <div className='card-text'>{get(metadata, 'description')}</div>
      <SocialBar metadata={metadata}/>
    </div>);
}


/*******************************************************************************
Subcomponents
*******************************************************************************/


function Icon({name, url, text}) {
  // Render nothing if no url is provided
  if (!url) { return null; }

  return (
    <a className='iconbox' href={url}>
      <i className={'text-24 pb-0 ' + name}/>
      <div className='line-height-100 text-16'>{text}</div>
    </a>);
}


function SocialBar({metadata}) {
  return (
    <div className='card-footer'>
      <Icon
        name={'far fa-file'}
        url={get(metadata, 'paper')}
        text={'Paper'}
      />
      <Icon
        name={'fas fa-code-branch'}
        url={get(metadata, 'github')}
        text={'Code'}
      />
      <Icon
        name={'fas fa-indent'}
        url={get(metadata, 'website')}
        text={'Website'}
      />
      <Icon
        name={'fas fa-film'}
        url={get(metadata, 'youtube')}
        text={'Video'}
      />
    </div>);
}


/*******************************************************************************
Utilities
*******************************************************************************/


function get(metadata, property) {
  try {
    return metadata[property];
  } catch(err) {
    return '';
  }
}
