import React from 'react';
import './css/home.css';
import selfie from './images/selfie.png'


/*******************************************************************************
Home page
*******************************************************************************/


export default function Home() {
  return (
    <div className='home text-20 px-1'>
      <div className='break'><br/></div>
      <img className='selfie' src={selfie} alt='Max Morrison' />
      <div className='break'><br/></div>
      <p className='text-36-responsive mb-2'>Bio</p>
      I am a research scientist at
      <a href='https://murf.ai'>
        &nbsp;Murf
      </a> building AI-enabled speech content creation
      software for podcasts, film dialogue, educational content, and more.
      I received my PhD from the
      <a href='https://interactiveaudiolab.github.io/'>
        &nbsp;Interactive Audio Lab&nbsp;
      </a>
      at Northwestern University (advised by
      <a href='https://bryan-pardo.github.io/'>
        &nbsp;Bryan Pardo
      </a>). My research interests include generative modeling, audio editing, and
      interpretable representations for interactive applications
      (e.g., content creation, language learning, or speech pathology).
      <div className='break'><br/></div>
      Before beginning my doctoral research, I attended the University
      of Michigan and obtained Bachelor's degrees in Computer Science
      and Performing Arts Technology.
      <div className='break'><br/><br/></div>
      {/* <b>I am currently on the job market seeking opportunities in audio research.</b>
      <div className='break'><br/><br/></div> */}
      <p className='text-36-responsive mx-0 mb-2'>News</p>
      <table>
        <tbody>
          <tr>
            <td className='news-date'><b>[Aug 2024]</b></td>
            <td>
              I started a full-time position as a research scientist with
              <a href='https://murf.ai'>&nbsp;Murf</a>.
            </td>
          </tr>
          <tr>
            <td className='news-date'><b>[Jun 2024]</b></td>
            <td>
              I successfully defended my PhD thesis,
              <a href='/pdfs/thesis.pdf'>
                <i> Interpretable Speech Representation and Editing</i>.
              </a>
            </td>
          </tr>
          <tr>
            <td className='news-date'><b>[Jun 2024]</b></td>
            <td>
              <a href='/pdfs/morrison2024fine.pdf'>
                <i>Fine-Grained and Interpretable Neural Speech Editing </i>
              </a>
              was accepted at Interspeech 2024.
            </td>
          </tr>
          <tr>
            <td className='news-date'><b>[Feb 2024]</b></td>
            <td>
              <a href='/pdfs/churchwell2024high.pdf'>
                <i>High-Fidelity Neural Phonetic Posteriorgrams </i>
              </a>
              was accepted at ICASSP 2024 Workshop on Explainable Machine Learning for Speech and Audio.
            </td>
          </tr>
          <tr>
            <td className='news-date'><b>[Dec 2023]</b></td>
            <td>
              <a href='/pdfs/morrison2024crowdsourced.pdf'>
                <i>Crowdsourced and Automatic Speech Prominence Estimation </i>
              </a>
              was accepted at ICASSP 2024.
            </td>
          </tr>
          <tr>
            <td className='news-date'><b>[Oct 2022]</b></td>
            <td>
              <a href='https://interactiveaudiolab.github.io/news/2022/10/01/Sony-grant.html'>
                My research proposal on controllable speech generation received a $100,000 grant from Sony Research
              </a>
            </td>
          </tr>
          <tr>
            <td className='news-date'><b>[Sep 2022]</b></td>
            <td>
              <a href='https://interactiveaudiolab.github.io/assets/papers/voiceblock_neurips.pdf'>
                <i>VoiceBlock: Privacy through Real-Time Adversarial Attacks with Audio-to-Audio Models </i>
              </a>
              was accepted at NeurIPS 2022.
            </td>
          </tr>
          <tr>
            <td className='news-date'><b>[Jul 2022]</b></td>
            <td>
              <a href='https://interactiveaudiolab.github.io/assets/papers/ismir2022-schaffer-et-al.pdf'>
                <i>Music Separation Enhancement with Generative Modeling </i>
              </a>
              was accepted at ISMIR 2022.
            </td>
          </tr>
          <tr>
            <td className='news-date'><b>[Apr 2022]</b></td>
            <td>
              <a href='/pdfs/morrison2022reproducible.pdf'>
                <i>Reproducible Subjective Evaluation </i>
              </a>
              was accepted at the ICLR 2022 Workshop on Machine Learning Evaluation Standards.
            </td>
          </tr>
          <tr>
            <td className='news-date'><b>[Feb 2022]</b></td>
            <td>
              <a href='/pdfs/morrison2022chunked.pdf'>
                <i>Chunked Autoregressive GAN for Conditional Waveform
                Synthesis </i>
              </a>
              was accepted at ICLR 2022.
            </td>
          </tr>
          <tr>
            <td className='news-date'><b>[Feb 2022]</b></td>
            <td>
              I completed an internship working on conditional audio generation
              at Descript.
            </td>
          </tr>
          <tr>
            <td className='news-date'><b>[Jan 2021]</b></td>
            <td>
              <a href='/pdfs/morrison2021context.pdf'>
                <i>Context-Aware Prosody Correction for Text-Based
                Speech Editing </i>
              </a>
              was accepted at ICASSP 2021.
            </td>
          </tr>
          <tr>
            <td className='news-date'><b>[Sep 2020]</b></td>
            <td>
              I completed an internship working on text-based speech editing
              with the Audio Research Group at Adobe.
            </td>
          </tr>
          <tr>
            <td className='news-date'><b>[Jul 2020]</b></td>
            <td>
              <a href='/pdfs/morrison2020controllable.pdf'>
                <i>Controllable Neural Prosody Generation </i>
              </a> was accepted at Interspeech 2020.
            </td>
          </tr>
          <tr>
            <td className='news-date'><b>[Apr 2020]</b></td>
            <td>
              I completed an internship working on controllable speech
              synthesis with the Audio Research Group at Adobe.
            </td>
          </tr>
          <tr>
            <td className='news-date'><b>[Sep 2019]</b></td>
            <td>
              I completed an internship working on speech prosody
              generation with the Audio Research Group at Adobe.
            </td>
          </tr>
          <tr>
            <td className='news-date'><b>[Aug 2019]</b></td>
            <td>
              <a href='/pdfs/morrison2019otomechanic.pdf'>
                <i>OtoMechanic: Auditory Automobile Diagnostics
                via Query-by-Example </i>
              </a>was accepted at DCASE 2019.
            </td>
          </tr>
          <tr>
            <td className='news-date'><b>[Apr 2018]</b></td>
            <td>
              I received the National Science Foundation (NSF) Graduate Research Fellowship (GRFP)
            </td>
          </tr>
          <br/>
        </tbody>
      </table>
    </div>
  );
}
