import React from 'react'
import ReactMarkdown from 'react-markdown'
import { HashLink } from 'react-router-hash-link';
import Site from '../site'

import { Audio, Download, Image } from '../sitecomponents'
import WavTable from '../wavtable'

import '../css/site.css'


export default function Cargan() {
  const name = 'cargan';
  return (
    <Site name={name}>
      <TableOfContents/>
      <Overview name={name}/>
      <Artifacts name={name}/>
      <ListeningExamples name={name}/>
      <DummyCitation/>
    </Site>);
}


function Artifacts({ name }) {
  let markdown = `## Artifacts of GAN-based waveform synthesis\n`;
  return (
    <div className='artifacts'>
      <section id='artifacts'>
        <ReactMarkdown>{markdown}</ReactMarkdown>
        <PeriodicityArtifacts name={name}/>
        <PitchArtifacts name={name}/>
        <BoundaryArtifacts name={name}/>
        <Metallicness name={name}/>
      </section>
    </div>
  );
}


function BoundaryArtifact({name, index}) {
  let alt = 'Spectrograms of original and generated audio demonstrating ' +
  'boundary artifacts';
  let columns = ['Original', 'CARGAN (- Prepend)', 'CARGAN'];
  index = index.toString();
  return (
    <div style={{'paddingBottom': '3.5rem'}}>
      <Image
        name={name}
        file={`${index}.svg`}
        alt={alt}
        width={1000}/>
      <WavTable
        name={name}
        experiment={index}
        extension={'mp3'}
        rows={['_']}
        columns={columns}/>
    </div>
  );
}


function BoundaryArtifacts({name}) {
  let markdown = `### Boundary artifacts (Section 4)\n` +
  `We provide five examples of boundary artifacts. For each example, ` +
  `we plot the spectrogram of the original audio as well as the result ` +
  `of spectrogram-to-waveform inversion using both CARGAN and CARGAN ` +
  `without prepending the autoregressive conditioning before the ` +
  `discriminator. Look for vertical lines in CARGAN (- Prepend) that are ` +
  `not present in the Original or CARGAN conditions. ` +
  `The audio of each condition is provided below each plot.`;
  let indices = [1, 17, 21, 23, 36];
  let examples = indices.map((index) =>
    <BoundaryArtifact name={name} key={index} index={index}/>
  );
  return (
    <div className='boundary-artifacts section'>
      <section id='boundary-artifacts'>
        <ReactMarkdown>{markdown}</ReactMarkdown>
        {examples}
      </section>
    </div>
  );
}


function DummyCitation() {
  return (<section id='citation'></section>);
}


function ListeningExamples({ name }) {
  let markdown = `## Listening examples\n`;
  return (
    <div className='listening-examples'>
      <section id='listening-examples'>
        <ReactMarkdown>{markdown}</ReactMarkdown>
        <SpeechExamples name={name}/>
        <MusicExamples name={name}/>
      </section>
    </div>
  );
}


function MetallicArtifact({name, index}) {
  let alt = 'Spectrograms of original and generated audio demonstrating ' +
  'metallicness';
  let columns = ['Original', 'CARGAN (- Loss balance)', 'CARGAN'];
  index = index.toString();
  return (
    <div style={{'paddingBottom': '3.5rem'}}>
      <Image
        name={name}
        file={`${index}-metallic.svg`}
        alt={alt}
        width={1000}/>
      <WavTable
        name={name}
        experiment={index}
        extension={'mp3'}
        rows={['_']}
        columns={columns}/>
    </div>
  );
}


function Metallicness({name}) {
  let markdown = `### Metallicness (Section 4.2)\n` +
  `We provide five examples of metallicness. For each example, ` +
  `we plot the spectrogram of the original audio as well as the result ` +
  `of spectrogram-to-waveform inversion using both CARGAN and CARGAN ` +
  `without rebalancing the loss weights. Look for increased ` +
  `high-frequency energy in CARGAN (- Loss balance) during ` +
  `[unvoiced fricatives](https://thesoundofenglish.org/fricative-consonants/)` +
  ` that are not present in the Original or CARGAN conditions. ` +
  `The audio of each condition is provided below each plot.`;
  let indices = [1, 47, 11, 86, 4];
  let examples = indices.map((index) =>
    <MetallicArtifact name={name} key={index} index={index}/>
  );
  return (
    <div className='metallic-artifacts section'>
      <section id='metallic-artifacts'>
        <ReactMarkdown>{markdown}</ReactMarkdown>
        {examples}
      </section>
    </div>
  );
}


function MusicExample({name, file, alt, caption}) {
  let columns = ['Original', 'HiFi-GAN', 'CARGAN', 'CARGAN-16k'];
  return (
    <div style={{'paddingBottom': '3.5rem'}}>
      <Image
        name={name}
        file={`${file}.svg`}
        alt={alt}
        caption={caption}
        width={1000}/>
      <WavTable
        name={name}
        experiment={file}
        extension={'mp3'}
        rows={['_']}
        columns={columns}/>
    </div>
  );
}


function MusicExamples({name}) {
  let markdown = `### Spectrogram inversion of music (Appendix J)`;
  return (
    <div className='music-examples section'>
      <section id='music-examples'>
        <ReactMarkdown>{markdown}</ReactMarkdown>
        <MusicLowFreq name={name}/>
        <MusicReverb name={name}/>
        <MusicPolyphony name={name}/>
        <MusicExamplesMUSDB name={name}/>
      </section>
    </div>
  );
}


function MusicExamplesMUSDB({name}) {
  let markdown = '#### Spectrogram inversion of MUSDB-HQ stems\n' +
  'We provide five examples of spectrogram-to-waveform inversion of ' +
  'stems from MUSDB-HQ not used during training.';
  let rows = ['Drums', 'Bass', 'Guitars', 'Synth', 'Vocals'];
  let columns = ['Original', 'HiFi-GAN', 'CARGAN', 'CARGAN-16k'];
  return (
    <div className='music-musdb section'>
      <section id='music-musdb'>
        <ReactMarkdown>{markdown}</ReactMarkdown>
        <WavTable
          name={name}
          experiment={'musdb'}
          extension={'mp3'}
          rows={rows}
          columns={columns}
        />
      </section>
    </div>
  );
}


function MusicLowFreq({name}) {
  let alt = 'Spectrograms of original and generated audio of a ' +
  'repeated kick drum sample with a high-pass filter with increasing ' +
  'frequency';
  let caption = 'Spectrogram visualizations for our low frequency modeling ' +
  'experiment on a repeated kick drum sample with a gradually increasing ' +
  'center frequency on a high-pass filter. Comparing the lowest frequencies ' +
  'of each transient shows that CARGAN-16k is most capable of modeling the ' +
  'effect of this high-pass filter.';
  return (
    <div>
      <section id='music-lowfreq'>
        <ReactMarkdown>{'#### Modeling low frequencies'}</ReactMarkdown>
        <MusicExample
          name={name}
          file={'kick-lowfreq'}
          alt={alt}
          caption={caption}
        />
      </section>
    </div>
  );
}


function MusicPolyphony({name}) {
  let alt = 'Spectrograms of original and generated audio of a ' +
  'MIDI piano with an increasing number of notes';
  let caption = 'Spectrogram visualizations for our polyphonic modeling ' +
  'experiment on a MIDI piano instrument with a gradually increasing number ' +
  'of notes. HiFi-GAN exhibits a strong vibrato that is easier to hear than ' +
  'to see on a spectrogram. CARGAN and CARGAN-16k exhibit periodic boundary ' +
  'artifacts which are clearly visible in the spectrogram.'
  return (
    <div>
      <section id='music-polyphony'>
        <ReactMarkdown>{'#### Modeling polyphony'}</ReactMarkdown>
        <MusicExample
          name={name}
          file={'piano-polyphony'}
          alt={alt}
          caption={caption}
        />
      </section>
    </div>
  );
}


function MusicReverb({name}) {
  let alt = 'Spectrograms of original and generated audio of a ' +
  'repeated snare drum sample with a reverb with increasing decay time';
  let caption = 'Spectrogram visualizations for our reverb modeling ' +
  'experiment on a repeated snare sample with a gradually increasing ' +
  'reverb decay time. All models are capable of reproducing reverb in ' +
  'this simple case. Comparing the high-frequencies of each transient ' +
  'shows that CARGAN-16k is most capable of accurately modeling the ' +
  'high-frequencies.';
  return (
    <div>
      <section id='music-reverb'>
        <ReactMarkdown>{'#### Modeling reverb'}</ReactMarkdown>
        <MusicExample
          name={name}
          file={'snare-reverb'}
          alt={alt}
          caption={caption}
        />
      </section>
    </div>
  );
}


function Overview({name}) {
  let caption = 'Overview of our proposed Chunked Autoregressive GAN ' +
  '(CARGAN) model for conditional waveform synthesis.';
  return (
    <div className='overview' style={{'paddingBottom': '2.5rem'}}>
      <section id='overview'>
        <Image
          name={name}
          file={'model.png'}
          alt={'CARGAN system flow'}
          caption={caption}
          width={1000}/>
      </section>
    </div>
  );
}


function PeriodicityArtifact({name, index, sentence}) {
  let alt = 'A plot showing an example of periodicity error';
  let columns = ['Original', 'HiFi-GAN', 'CARGAN'];
  return (
    <div style={{'paddingBottom': '3.5rem'}}>
      <Image
        name={name}
        file={`${index}.svg`}
        alt={alt}
        width={1000}
        caption={`${sentence}`}/>
      <WavTable
        name={name}
        experiment={index}
        extension={'mp3'}
        rows={['_']}
        columns={columns}/>
    </div>
  );
}


function PeriodicityArtifacts({name}) {
  let markdown = `### Periodicity artifacts (Section 2)\n` +
  `We provide examples of ten periodicity errors. For each example, ` +
  `we plot the periodicity curves of the original audio as well as the result ` +
  `of spectrogram-to-waveform inversion using both HiFi-GAN and CARGAN. ` +
  `The audio of each condition is provided below each plot, as well as a ` +
  `transcript with the words to listen for in bold.`;
  let indices = [
    '10',
    '26',
    '43',
    '74',
    '81',
    '25-daps',
    '45-daps',
    '47-daps',
    '57-daps',
    '74-daps'];
  let sentences = [
    '**Everyone** has to prove a point **when** they cross that white line.',
    'Does the **name** matter?',
    'Similar **measures** are expected in England and Whales.',
    'We **also** enjoyed **a lot** of support.',
    'It isn\'t a happy **memory**.',
    'And were **now** lying becalmed about half a mile to the south-east of the **low** eastern coast',
    'With its grey, **melancholy** woods, **and** wild stone spires',
    'This standing still and being rolled about like a bottle was a **thing I never** learned to stand without a qualm or so.',
    'Grey, **colored** woods covered a large part of the surface.',
    'The **Hispaniola** was rolling scuppers under in the ocean swell.']
  let examples = indices.map((index, i) =>
    <PeriodicityArtifact name={name} key={i} index={index} sentence={sentences[i]}/>
  );
  return (
    <div className='periodicity-artifacts section'>
      <section id='periodicity-artifacts'>
        <ReactMarkdown>{markdown}</ReactMarkdown>
        {examples}
        <PeriodicityOther name={name}/>
      </section>
    </div>
  );
}


function PeriodicityHiFiGAN({name}) {
  let markdown = `#### Periodicity artifacts from the ` +
  `[demo page](https://jik876.github.io/hifi-gan-demo/) of ` +
  `[HiFi-GAN](https://arxiv.org/abs/2010.05646)\n`;
  let columns = ['Original', 'Generated'];
  let rows = ['beyond', 'man', 'universal'];
  return (
    <div className='periodicity-hifigan section'>
      <section id='periodicity-hifigan'>
        <ReactMarkdown>{markdown}</ReactMarkdown>
        <WavTable
          name={name}
          experiment={'hifigan'}
          extension={'mp3'}
          rows={rows}
          columns={columns}
        />
      </section>
    </div>
  );
}


function PeriodicityPWG({name}) {
  let markdown = `#### Periodicity artifacts from the ` +
  `[demo page](https://r9y9.github.io/demos/projects/icassp2020/) of ` +
  `[Parallel WaveGAN](https://arxiv.org/abs/1910.11480)\n`;
  let columns = ['Original', 'Generated'];
  let rows = ['loyalty', 'no'];
  return (
    <div className='periodicity-pwg section'>
      <section id='periodicity-pwg'>
        <ReactMarkdown>{markdown}</ReactMarkdown>
        <WavTable
          name={name}
          experiment={'pwg'}
          extension={'mp3'}
          rows={rows}
          columns={columns}
        />
      </section>
    </div>
  );
}


function PeriodicitySMG({name}) {
  let markdown = `#### Periodicity artifacts from the ` +
  `[demo page](https://fhgspco.github.io/smgan/) of ` +
  `[StyleMelGAN](https://arxiv.org/abs/2011.01557)\n`;
  let columns = ['Original', 'Generated'];
  let rows = ['stops', 'striking'];
  return (
    <div className='periodicity-smg section'>
      <section id='periodicity-smg'>
        <ReactMarkdown>{markdown}</ReactMarkdown>
        <WavTable
          name={name}
          experiment={'smg'}
          extension={'mp3'}
          rows={rows}
          columns={columns}
        />
      </section>
    </div>
  );
}


function PeriodicityOther({name}) {
  let markdown = `#### Periodicity artifacts from demo pages of other works\n` +
  `We collect examples that exhibit the same periodicity error from the demo ` +
  `webpages of other neural vocoders. The word containing the artifact is ` +
  `given at the start of each row. We note that the presence of these ` +
  `errors on the demo webpages indicates that even hand-selected examples ` +
  `by the authors contain this artifact. This highlights the severity of ` +
  `the artifact addressed by CARGAN.`;
  return (
    <div className='periodicity-others section'>
      <section id='periodicity-others'>
        <ReactMarkdown>{markdown}</ReactMarkdown>
        <PeriodicityHiFiGAN name={name}/>
        <PeriodicityPWG name={name}/>
        <PeriodicitySMG name={name}/>
      </section>
    </div>
  );
}


function PitchArtifact({name, index, sentence}) {
  let alt = 'A plot showing an example of pitch intonation error';
  let columns = ['Original', 'HiFi-GAN', 'CARGAN'];
  index = index.toString();
  return (
    <div style={{'paddingBottom': '3.5rem'}}>
      <Image
        name={name}
        file={`${index}.svg`}
        alt={alt}
        width={1000}
        caption={`${sentence}`}/>
      <WavTable
        name={name}
        experiment={index}
        extension={'mp3'}
        rows={['_']}
        columns={columns}/>
    </div>
  );
}


function PitchArtifacts({name}) {
  let markdown = `### Pitch artifacts (Section 2)\n` +
  `We provide examples of five pitch intonation errors. For each example, ` +
  `we plot the pitch curves of the original audio as well as the result ` +
  `of spectrogram-to-waveform inversion using both HiFi-GAN and CARGAN. ` +
  `The audio of each condition is provided below each plot, as well as a ` +
  `transcript with the words to listen for in bold.`;
  let indices = [38, 52, 75, 82, 87];
  let sentences = [
    'I can\'t **believe it**.',
    '**And we** talking **big money**',
    'I have no **battle** with the Scots.',
    'It is **annoying**.',
    '**Nobody will** know the feeling of the branches until October.'];
  let examples = indices.map((index, i) =>
    <PitchArtifact
      name={name}
      key={i}
      index={index}
      sentence={sentences[i]}/>
  );
  return (
    <div className='pitch-artifacts section'>
      <section id='pitch-artifacts'>
        <ReactMarkdown>{markdown}</ReactMarkdown>
        {examples}
      </section>
    </div>
  );
}


function SpeechExamples({name}) {
  let markdown = `### Spectrogram inversion of speech (Section 5.2.1)`;
  let rows = [
    'Female 1', 'Male 1',
    'Female 2', 'Male 2',
    'Female 3', 'Male 3',
    'Female 4', 'Male 4',
    'Female 5', 'Male 5'];
  return (
    <div className='speech-examples section'>
      <section id='speech-examples'>
        <ReactMarkdown>{markdown}</ReactMarkdown>
        <SpeechExamplesVCTK name={name} rows={rows}/>
        <SpeechExamplesDAPS name={name} rows={rows}/>
      </section>
    </div>
  );
}


function SpeechExamplesDAPS({name, rows}) {
  let markdown = `#### DAPS`;
  let columns = ['Original', 'HiFi-GAN', 'CARGAN'];
  return (
    <div className='speech-examples-daps section'>
      <section id='speech-examples-daps'>
        <ReactMarkdown>{markdown}</ReactMarkdown>
        <WavTable
          name={name}
          experiment={'daps'}
          extension={'mp3'}
          rows={rows}
          columns={columns}
        />
        <Download
          name={name}
          file={'daps.tar.gz'}
          text={'Download all DAPS subjective evaluation data'}
        />
      </section>
    </div>
  );
}


function SpeechExamplesVCTK({name, rows}) {
  let markdown = `#### VCTK`;
  let columns = [
    'Original',
    'HiFi-GAN',
    'CARGAN',
    '- GANTTS',
    '- Loss balance',
    '- Prepend'];
  return (
    <div className='speech-examples-vctk section'>
      <section id='speech-examples-vctk'>
        <ReactMarkdown>{markdown}</ReactMarkdown>
        <WavTable
          name={name}
          experiment={'vctk'}
          extension={'mp3'}
          rows={rows}
          columns={columns}
        />
        <Download
          name={name}
          file={'vctk.tar.gz'}
          text={'Download all VCTK subjective evaluation data'}
        />
      </section>
    </div>
  );
}


function TableOfContents() {
  let location = '/sites/cargan';
  return (
    <div className='section'>
      <ReactMarkdown>{`## Table of Contents`}</ReactMarkdown>
      <ul>
        <li>
          <HashLink to={`${location}/#overview`}>Overview</HashLink>
        </li>
        <li>
          <HashLink to={`${location}/#artifacts`}>Artifacts of GAN-based waveform synthesis</HashLink>
          <ul>
            <li>
              <HashLink to={`${location}/#periodicity-artifacts`}>Periodicity inaccuracy (Section 2)</HashLink>
              <ul>
                <li>
                  <HashLink to={`${location}/#periodicity-others`}>From demo pages of other works</HashLink>
                  <ul>
                    <li>
                      <HashLink to={`${location}/#periodicity-hifigan`}>HiFi-GAN</HashLink>
                    </li>
                    <li>
                      <HashLink to={`${location}/#periodicity-pwg`}>Parallel WaveGAN</HashLink>
                    </li>
                    <li>
                      <HashLink to={`${location}/#periodicity-smg`}>Style MelGAN</HashLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <HashLink to={`${location}/#pitch-artifacts`}>Pitch inaccuracy (Section 2)</HashLink>
            </li>
            <li>
              <HashLink to={`${location}/#boundary-artifacts`}>Boundary artifacts (Section 4)</HashLink>
            </li>
            <li>
              <HashLink to={`${location}/#metallic-artifacts`}>Metallicness (Section 4.2)</HashLink>
            </li>
          </ul>
        </li>
        <li>
          <HashLink to={`${location}/#listening-examples`}>Listening examples</HashLink>
          <ul>
            <li>
              <HashLink to={`${location}/#speech-examples`}>Spectrogram inversion of speech (Section 5.2.1)</HashLink>
              <ul>
                <li>
                  <HashLink to={`${location}/#speech-examples-vctk`}>VCTK</HashLink>
                </li>
                <li>
                  <HashLink to={`${location}/#speech-examples-daps`}>DAPS</HashLink>
                </li>
              </ul>
            </li>
            <li>
              <HashLink to={`${location}/#music-examples`}>Spectrogram inversion of music (Appendix J)</HashLink>
              <ul>
                <li>
                  <HashLink to={`${location}/#music-lowfreq`}>Modeling low frequencies</HashLink>
                </li>
                <li>
                  <HashLink to={`${location}/#music-reverb`}>Modeling reverb</HashLink>
                </li>
                <li>
                  <HashLink to={`${location}/#music-polyphony`}>Modeling polyphony</HashLink>
                </li>
                <li>
                  <HashLink to={`${location}/#music-musdb`}>Spectrogram inversion of MUSDB-HQ stems</HashLink>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <HashLink to={`${location}/#citation`}>How to cite this work</HashLink>
        </li>
      </ul>
    </div>
  );
}
