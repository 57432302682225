import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useRouteMatch } from "react-router-dom";

import './css/sitecomponents.css'


/*******************************************************************************
Components
*******************************************************************************/


export function Image({ name, file, alt, caption, width, audio }) {
  let path = useRouteMatch().path;
  // let path = `/sites/${name}`;

  return (
    <div className={'imagebox' + (audio ? '' : ' spacer')} >
      <img
        alt={alt}
        className='image'
        src={`${path}/images/${file}`}
        style={{ maxWidth: width }} />
      {caption ? <ImageCaption text={caption} width={width} /> : null}
      {audio ? <Audio name={name} file={audio} /> : null}
    </div>
  );
};


export function Table({ data }) {
  const columns = Object.keys(data[0]);
  return (
    <table className='simple-table'>
      <tr>
        {columns.map((col, j) => <th className='simple-table-cell' key={j}>{col}</th>)}
      </tr>
      {data.map((row, i) => {
        return (
          <tr bgcolor={i % 2 === 0 ? '#eee' : '#fff'} key={i}>
            {
              columns.map((key, j) => <td className='simple-table-cell' key={(i + 1) * j}>
                {row[key]}
              </td>)
            }
          </tr>
        )
      })}
    </table>
  );
}


/*******************************************************************************
Subcomponents
*******************************************************************************/


export function Audio({ name, file, title, caption }) {
  let path = useRouteMatch().path;
  // let path = `/sites/${name}`;
  return (
    <div>
      {title ? <div className='audio-title'>{title}</div> : null}
      <audio className='audio-single' controls>
        <source src={`${path}/audio/${file}`} type='audio/mpeg' />
      </audio>
      {caption ? <div className='caption'>{caption}</div> : null}
    </div>);
};


export function Download({ file, url, text }) {
  if (url === undefined) {
    url = file;
  }

  return (
    <a className='iconbox download text-24-responsive' href={url}>
      {text}
    </a>
  )
}


function ImageCaption({ text, width }) {
  return (
    <div className='caption' style={{ maxWidth: width }}>
      <ReactMarkdown>{text}</ReactMarkdown>
    </div>);
}
