import BibtexParse from 'bibtex-parse'
import React, {useState, useEffect} from 'react'

import Publication from './publication'

import './css/bibliography.css'
import './css/responsive-text.css'


/*******************************************************************************
Bibliography
*******************************************************************************/


export default function Bibliography() {
  //  Load bibtex on mount
  const [bibtex, setBibtex] = useState(null);
  useEffect(() => {
    fetch(process.env.PUBLIC_URL + '/publications.bibtex')
      .then((response) => response.text())
      .then((text) => setBibtex(BibtexParse.entries(text)));
  }, []);

  // Render nothing if bibtex hasn't loaded
  if (!bibtex) { return null; }

  // Create a publication object for each bibtex entry
  const bibliography = bibtex.map(
    (entry, index) => <Publication metadata={entry} key={index}/>);

  // Render
  return (
    <div className='bibliography'>
      <div className='pub-header text-24-responsive'>
        <div className='arrow-ghost'></div>
        <div className='date'>Date</div>
        <div className='d-flex w-100 row justify-content-center'>
          <div className='col px-1'>Title</div>
          <div className='col px-1'>Authors</div>
        </div>
      </div>
      {bibliography}
    </div>);
}
