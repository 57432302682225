import React from 'react';

import './css/notfound.css';


/*******************************************************************************
404 handler
*******************************************************************************/


export default function NotFound() {
  return <div className='error'>404</div>;
}
