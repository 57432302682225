import { useEffect, useState } from 'react';


/*******************************************************************************
Utility hooks
*******************************************************************************/


export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}


export function useWindowSizeDelta() {
  const [delta, setDelta] = useState({
    width: 0,
    height: 0
  });
  const [prevSize, setPrevSize] = useState({
    width: undefined,
    height: undefined
  });
  const windowSize = useWindowSize();

  // Update delta
  useEffect(() => {
    setDelta({
      width: windowSize.width - prevSize.width,
      height: windowSize.height - prevSize.height
    });
  }, [prevSize, windowSize]);

  // Update previous size
  useEffect(() => setPrevSize(windowSize), [windowSize]);

  return delta;
}
