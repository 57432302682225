import React from 'react';


/*****************************************************************************
Utilities
*****************************************************************************/


export default function BibTex({bibtex}) {
  /* Render the publication bibtex */
  let pages = get(bibtex, 'pages');
  return (
    <div>
      {`@${get(bibtex, 'type')}{${get(bibtex, 'key')},`}
      <div className='indent'>
        {`title={${get(bibtex, 'title')}},`}
      </div>
      <div className='indent'>
        {`author={${getAuthors(bibtex)}},`}
      </div>
      <div className='indent'>
        {`booktitle={${get(bibtex, 'booktitle')}},`}
      </div>
      <div className='indent'>
        {pages ? `pages={${pages}},` : null}
      </div>
      <div className='indent'>
         {`month={${get(bibtex, 'month')}},`}
      </div>
      <div className='indent'>
        {`year={${get(bibtex, 'year')}}`}
      </div>
      <div>{`}`}</div>
    </div>);
}


/*****************************************************************************
Utilities
*****************************************************************************/


function get(bibtex, property) {
  /* Retrieve one piece of bibtex */
  try {
    if (['key', 'type'].includes(property)) {
      return bibtex[property];
    } else {
      return bibtex[property.toUpperCase()];
    }
  } catch(error) { return null; }
}


function getAuthors(bibtex) {
  /* Retrieve the author list */
  return get(bibtex, 'author').replace('\\\'e', 'é');
}
