import React from 'react'
import ReactMarkdown from 'react-markdown'
import { HashLink } from 'react-router-hash-link';
import Site from '../site'

import { Audio, Download, Image } from '../sitecomponents'
import WavTable from '../wavtable'

import '../css/site.css'


/*******************************************************************************
Constants
*******************************************************************************/


const NAME = 'ppgs';


/*******************************************************************************
PPG site
*******************************************************************************/


export default function PPGs() {
  return (
    <Site name={NAME}>
      <TableOfContents />
      <Interpolation />
      <Reconstruction />
      <PitchShifting />
      <AccentConversion />
      <SingingTransfer />
      <Onomatopoeia />
      <DummyCitation />
    </Site>);
}


/*******************************************************************************
Site structure
*******************************************************************************/


function DummyCitation() {
  return (<section id='citation'></section>);
}


function TableOfContents() {
  let location = '/sites/ppgs';
  return (
    <div className='section'>
      <ReactMarkdown>{`## Table of Contents`}</ReactMarkdown>
      <ul style={{marginBottom: 0}}>
        <li>
          <HashLink to={`${location}/#interpolation`}>
            Pronunciation interpolation (Page 2; Figure 1)
          </HashLink>
        </li>
        <li>
          <HashLink to={`${location}/#reconstruction`}>
            PPG reconstruction quality (Page 3; Section 3.6)
          </HashLink>
        </li>
        <li>
          <HashLink to={`${location}/#pitch-shifting`}>
            Pitch-shifting (Page 3; Section 3.5)
          </HashLink>
        </li>
        <li>
          <HashLink to={`${location}/#accent-conversion`}>
            Accent conversion (Page 4; Section 4.2)
          </HashLink>
        </li>
        <li>
          <HashLink to={`${location}/#singing-transfer`}>
            Singing voice transfer (Page 4; Section 4.2)
          </HashLink>
        </li>
        <li>
          <HashLink to={`${location}/#automatic-onomatopoeia`}>
            Automatic onomatopoeia (Page 4; Section 4.2)
          </HashLink>
        </li>
        <li>
          <HashLink to={`${location}/#citation`}>
            How to cite this work
          </HashLink>
        </li>
      </ul>
    </div>
  );
}


/*******************************************************************************
Site contents
*******************************************************************************/


function AccentConversion() {
  let markdown1 = `## Accent conversion (Page 4; Section 4.2)\n` +
  `We propose a new style of accent conversion utilizing PPGs. Prior work ` +
  `performs accent conversion by generating PPGs in the distribution of ` +
  `the native speaker, but this doesn't preserve prosody. Instead, we ` +
  `propose regex-style editing of PPGs, in which phonemes, diphones, ` +
  `and triphones within the PPG can be flexibly substituted. We consider ` +
  `a regex match in a PPG to occur when the non-repeating sequence of ` +
  `consecutive phonemes matches a query. We replace regex matches by ` +
  `swapping corresponding phoneme probabilities with phoneme probabilities ` +
  `of second query of equal length. The probabilities corresponding to all ` +
  `phonemes other than the two query phonemes remain unchanged.`;
  let markdown2 = `We use our proposed regex-based accent conversion to convert a British ` +
  `English accent to a classic Chicago accent. We use the following regex ` +
  `substitutions:\n` +
  `* Monophone substitutions\n` +
  `  * "th" -> "d"\n` +
  `  * "dh" -> "d"\n` +
  `  * "ng" -> "n"\n` +
  `  * "ih" -> "iy"\n` +
  `  * "eh" -> "ih"\n` +
  `  * "ah" -> "eh"\n` +
  `  * "aa" -> "ae"\n` +
  `  * "uw" -> "aw"\n` +
  `* Diphone substitutions\n` +
  `  * "n", "d" -> "n", "n"\n` +
  `* Triphone substitutions\n` +
  `  * "aa", "r", "t" -> "aa", "aa", "t"`;
  return (
    <div className='section'>
      <section id='accent-conversion'>
        <ReactMarkdown>{markdown1}</ReactMarkdown>
        <br/>
        <ReactMarkdown>{markdown2}</ReactMarkdown>
        <WavTable
          name={NAME}
          experiment={'accent'}
          extension={'mp3'}
          columns={['Input', 'Output']}
          rows={['_']}
        />
      </section>
    </div>
  );
}


function Interpolation() {
  let markdown = `## Pronunciation interpolation (Page 2; Figure 1)\n` +
  `VITS model trained for 800k steps on PPGs inferred from wav2vec 2.0 features.`;
  return (
    <div className='section'>
      <section id='interpolation'>
        <ReactMarkdown>{markdown}</ReactMarkdown>
        <Audio
          name={NAME}
          file={'interpolation/Source.mp3'}
          title={'Source'}
        />
        <Image
          name={NAME}
          file={'interpolation.png'}
          alt={'Visualization of pronunciation interpolation using PPGs'}
          width={1100}
        />
        <WavTable
          name={NAME}
          experiment={'interpolation'}
          extension={'mp3'}
          columns={['Voice conversion', 'Interpolation', 'Manual edit']}
          rows={['_']}
        />
      </section>
    </div>
  );
}


function Onomatopoeia() {
  let markdown = `## Automatic onomatopoeia (Page 4; Section 4.2)\n` +
  `Analysis-synthesis using PPGs demonstrates an emergent property of ` +
  `automatic onomatopoeia, in which extracting PPGs from non-speech ` +
  `audio and synthesizing in a target voice produces a vocal imitation. ` +
  `This does not yet work for all sounds; that generalization ` +
  `is a direction for future work. VITS model trained for 800k steps on PPGs inferred from wav2vec 2.0 features.`;
  return (
    <div className='section'>
      <section id='automatic-onomatopoeia'>
        <ReactMarkdown>{markdown}</ReactMarkdown>
        <WavTable
          name={NAME}
          experiment={'onomatopoeia'}
          extension={'mp3'}
          columns={['Source', 'Target', 'Output']}
          rows={['Cat', 'Dog']}
        />
      </section>
    </div>
  );
}


function PitchShifting() {
  let markdown = `## Pitch-shifting (Page 3; Section 3.5)\n` +
  `We provide examples of pitch-shifting by ±200¢ using all representations considered in our work. Ordering of rows is the same as in Table 1. All VITS models trained for 250k steps.`;
  return (
    <div className='section'>
      <section id='pitch-shifting'>
        <ReactMarkdown>{markdown}</ReactMarkdown>
        <WavTable
          name={NAME}
          experiment={'pitch-source'}
          extension={'mp3'}
          columns={['Female', 'Male']}
          rows={['Source']}
        />
        <WavTable
          name={NAME}
          experiment={'pitch-shift'}
          extension={'mp3'}
          columns={['Female (↓200¢)', 'Female (↑200¢)', 'Male (↓200¢)', 'Male (↑200¢)']}
          rows={[
            'Mels',
            'Mels (PPG)',
            'Wav2vec 2.0',
            'Wav2vec 2.0 (PPG)',
            'Charsiu',
            'Charsiu (PPG)',
            'ASR bottleneck',
            'ASR bottleneck (PPG)',
            'EnCodec',
            'EnCodec (PPG)']}
        />

      </section>
    </div>
  );
}


function Reconstruction() {
  let markdown = `## PPG reconstruction quality (Page 3; Section 3.6)\n` +
  `We compare the quality of speech reconstruction from PPGs inferred from five input representations. All VITS models trained for 250k steps.`;
  return (
    <div className='section'>
      <section id='reconstruction'>
        <ReactMarkdown>{markdown}</ReactMarkdown>
        <WavTable
          name={NAME}
          experiment={'reconstruction'}
          extension={'mp3'}
          columns={['Female 1', 'Male 1', 'Female 2', 'Male 2']}
          rows={[
            'Original',
            'Wav2vec 2.0',
            'EnCodec',
            'Mels',
            'ASR bottleneck',
            'Charsiu']}
        />
      </section>
    </div>
  );
}


function SingingTransfer() {
  let markdown = `## Singing voice transfer (Page 4; Section 4.2)\n` +
    `While not trained on singing, our speech synthesizer trained on PPGs and pitch also ` +
    `demonstrates the ability to perform singing voice transfer. VITS model trained for 800k steps on PPGs inferred from wav2vec 2.0 features.`;
  return (
    <div className='section'>
      <section id='singing-transfer'>
        <ReactMarkdown>{markdown}</ReactMarkdown>
        <Audio
          name={NAME}
          file={'singing/Source.mp3'}
          title={'Source'}
        />
        <WavTable
          name={NAME}
          experiment={'singing'}
          extension={'mp3'}
          columns={['Target', 'Output']}
          rows={['Male', 'Female']}
        />
      </section>
    </div>
  );
}
