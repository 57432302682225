import React from 'react'
import ReactMarkdown from 'react-markdown'
import { HashLink } from 'react-router-hash-link';
import Site from '../site'
import { Audio, Download, Image } from '../sitecomponents'

import WavTable from '../wavtable'

import '../css/site.css'


/*******************************************************************************
Controllable lpcnet site
*******************************************************************************/


export default function ControllableLpcnet() {
  const name = 'controllable-lpcnet';

  return (
    <Site name={name}>
      <TableOfContents/>
      <Overview name={name}/>
      <PitchRepresentation name={name}/>
      <Variable name={name}/>
      <ConstantPitch name={name}/>
      <ConstantDuration name={name}/>
      <DummyCitation/>
    </Site>
  );
}


/*******************************************************************************
Sections
*******************************************************************************/


function ConstantPitch({ name }) {
  let markdown = `## Constant-ratio pitch-shifting\n` +
    `We provide audio examples from the DAPS dataset. ` +
    `We use one utterance each from two male and ` +
    `two female speakers. We use three conditions (PSOLA, LPCNet, and ` +
    `CLPCNet). For each condition we provide examples for pitch-shifting ` +
    `with five constant ratios: 0.67, 0.80, 1.00, 1.25, and 1.50.`;
  const columns =['0.67', '0.80', '1.00', '1.25', '1.50'];
  return (
    <div className='constant-pitch'>
      <section id='constant-pitch'>
        <ReactMarkdown source={markdown}/>
        <ConstantAudioTable
          name={name}
          experiment={'constant-pitch/Female 1'}
          example={'Female 1'}
          columns={columns}/>
        <ConstantAudioTable
          name={name}
          experiment={'constant-pitch/Male 1'}
          example={'Male 1'}
          columns={columns} />
        <ConstantAudioTable
          name={name}
          experiment={'constant-pitch/Female 2'}
          example={'Female 2'}
          columns={columns} />
        <ConstantAudioTable
          name={name}
          experiment={'constant-pitch/Male 2'}
          example={'Male 2'}
          columns={columns} />
        <Download
          url={'https://drive.google.com/uc?id=1fKjAugEKQpVMIfKslu9zG2ylhIxSG0Xw&export=download'}
          text={'Download all constant-ratio pitch-shifting subjective evaluation data'} />
      </section>
      <br/>
    </div>);
}


function ConstantDuration({ name }) {
  let markdown = `## Constant-ratio time-stretching\n` +
    `We provide audio examples from the DAPS dataset. ` +
    `We use one utterance each from two male and ` +
    `two female speakers. We use three conditions (PSOLA, LPCNet, and ` +
    `CLPCNet). For each condition we provide examples for time-stretching ` +
    `with five constant ratios: 0.50, 0.71, 1.00, 1.41, and 2.00.`;
  const columns = ['0.50', '0.71', '1.00', '1.41', '2.00'];
  return (
    <div className='constant-duration'>
      <section id='constant-duration'>
        <ReactMarkdown source={markdown} />
        <ConstantAudioTable
          name={name}
          experiment={'constant-duration/Female 1'}
          example={'Female 1'}
          columns={columns} />
        <ConstantAudioTable
          name={name}
          experiment={'constant-duration/Male 1'}
          example={'Male 1'}
          columns={columns} />
        <ConstantAudioTable
          name={name}
          experiment={'constant-duration/Female 2'}
          example={'Female 2'}
          columns={columns} />
        <ConstantAudioTable
          name={name}
          experiment={'constant-duration/Male 2'}
          example={'Male 2'}
          columns={columns} />
        <Download
          url={'https://drive.google.com/uc?id=1B34vaeDy8KgXBpf-7E3QsqDlG66j2KJF&export=download'}
          text={'Download all constant-ratio time-stretching subjective evaluation data'} />
      </section>
      <br/>
    </div>);
}


function ConstantAudioTable({ name, experiment, example, columns }) {
  return (
    <div className='section'>
      <ReactMarkdown source={`#### ${example}`}/>
      <Audio
        name={name}
        title={'Original'}
        file={`constant-original/${example}.mp3`} />
      <WavTable
        name={name}
        experiment={experiment}
        extension={'mp3'}
        rows={['TD-PSOLA', 'WORLD', 'LPCNet', 'CLPCNet']}
        columns={columns}/>
    </div>
  );
}


function DummyCitation() {
  return (<section id='citation'></section>);
}


function Overview({ name }) {
  const alt = 'A graph showing that LPCNet cannot perform pitch-shifting ' +
  'and CLPCNet can.';
  const caption = 'Pitch-shifting with LPCNet and our proposed CLPCNet. The ' +
  'target pitch is two-thirds the original pitch. The audio corresponding ' +
  'to the Original, LPCNet, and CLPCNet pitch contours are below.';
  const columns = ['Original', 'LPCNet', 'CLPCNet (ours)'];
  const rows = ['_'];
  return (
    <div className='section'>
      <section id='overview'>
        <Image
          name={name}
          file={'figure.svg'}
          alt={alt}
          caption={caption}
          width={800}/>
        <WavTable
          name={name}
          experiment={'overview'}
          extension={'mp3'}
          rows={rows}
          columns={columns}/>
      </section>
    </div>
  );
}


function PitchRepresentation({ name }) {
  const markdown = `## Pitch Representation\n` +
    `Our work proposes using CREPE to extract pitch features instead of ` +
    `YIN. To understand the importance of this decision, we show the pitch ` +
    `and periodicity of CREPE and YIN on a randomly selected example.`;
  const alt = 'Plots of the pitch and periodicity of CREPE and YIN';
  const caption = `Plots of the pitch and periodicity of CREPE and YIN. ` +
    `The YIN pitch is erratic in unvoiced regions. The YIN periodicity ` +
    `is higher in unvoiced regions and lower in voiced regions, which makes ` +
    `it less accurate at determining voiced/unvoiced boundary locations.`;
  return (
    <div className='section'>
      <section id='pitch-representation'>
        <ReactMarkdown source={markdown} />
        <Image
          name={name}
          file={'pitch.svg'}
          alt={alt}
          caption={caption}
          audio={'pitch.mp3'}
          width={800} />
      </section>
    </div>
  );
}


function TableOfContents() {
  let location = '/sites/controllable-lpcnet';
  return (
    <div className='section'>
      <ReactMarkdown source={`## Table of Contents`} />
      <ul>
        <li>
          <HashLink to={`${location}/#overview`}>Overview</HashLink>
        </li>
        <li>
          <HashLink to={`${location}/#pitch-representation`}>Pitch Representation</HashLink>
        </li>
        <li>
          <HashLink to={`${location}/#variable`}>Variable-ratio pitch-shifting and time-stretching</HashLink>
        </li>
        <li>
          <HashLink to={`${location}/#constant-pitch`}>Constant-ratio pitch-shifting</HashLink>
        </li>
        <li>
          <HashLink to={`${location}/#constant-duration`}>Constant-ratio time-stretching</HashLink>
        </li>
        <li>
          <HashLink to={`${location}/#citation`}>How to cite this work</HashLink>
        </li>
      </ul>
    </div>
  );
}


function Variable({ name }) {
  let markdown = `## Variable-ratio pitch-shifting and time-stretching\n` +
    `Here, the goal is to modify the pitch and timing of a recorded ` +
    `utterance (Original) to match the pitch and timing of a second ` +
    `utterance (Target) that contains the same words and speaker. ` +
    `This requires pitch-shifting and time-stretching with ratios that ` +
    `vary over the course of the utterance. Columns labeled "TD-PSOLA", ` +
    `"WORLD", "LPCNet", and "CLPCNet (ours)" illustrate the output of ` +
    `modifying "Original" to match "Target" ` +
    `using each of these approaches. ` +
    `We provide audio examples from the RAVDESS dataset (preprocessed ` +
    `with HiFi-GAN) using one utterance each from four male and ` +
    `four female speakers.`;
  return (
    <div className='section'>
      <section id='variable'>
        <ReactMarkdown source={markdown}/>
        <WavTable
          name={name}
          experiment={'variable'}
          extension={'mp3'}
          rows={['Female 1',
                'Male 1',
                'Female 2',
                'Male 2',
                'Female 3',
                'Male 3',
                'Female 4',
                'Male 4']}
          columns={['Original', 'Target', 'TD-PSOLA', 'WORLD', 'LPCNet', 'CLPCNet (ours)']}/>
        <Download
          url={'https://drive.google.com/uc?id=1T3rhO3pkfnoHAmdcV-pXbJis4Y1KWxPt&export=download'}
          text={'Download all variable-ratio subjective evaluation data'}/>
      </section>
    </div>
  );
}
