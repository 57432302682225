import React from 'react'
import ReactMarkdown from 'react-markdown'
import Site from '../site'

import { Download, Image } from '../sitecomponents'

import '../css/site.css'


export default function ProminenceEstimation() {
  const name = 'prominence-estimation';
  return (
    <Site name={name}>
      <Annotations name={name}/>
      <Examples name={name}/>
    </Site>);
}


function Annotations({ name }) {
  let markdown = `### Annotations\n`;
  return (
    <div className='annotations'>
      <ReactMarkdown>{markdown}</ReactMarkdown>
      <Download
        name={name}
        url={'https://zenodo.org/records/10402793'}
        text={'Download our proposed crowdsourced emphasis annotations'}
      />
    </div>
  );
}


function Examples({ name }) {
  let markdown = `### Examples\n Two examples of ground truth prominence from our proposed dataset (blue) and corresponding inferred prominence from our best model (orange). Gray indicates overlap.`;
  return (
    <div className='examples'>
      <ReactMarkdown>{markdown}</ReactMarkdown>
      <Image
        name={name}
        alt={'An example of ground truth prominence from our proposed dataset and corresponding inferred prominence from our best model'}
        width={'100%'}
        file={'40_121026_000042_000001.png'}
        audio={'40_121026_000042_000001.wav'}
      />
      <Image
        name={name}
        alt={'An example of ground truth prominence from our proposed dataset and corresponding inferred prominence from our best model'}
        caption={'Two examples of ground truth prominence from our proposed dataset (blue) and corresponding inferred prominence from our best model (orange)'}
        width={'100%'}
        file={'196_122159_000012_000003.png'}
        audio={'196_122159_000012_000003.wav'}
      />
    </div>
  );
}
